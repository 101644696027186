function downloadSubtitle(queryDescription, useHtml) {
  const subtitle = `Data for ${queryDescription}`;

  if (useHtml) {
    return subtitle;
  } else {
    return subtitle.replace(/(<([^>]+)>)/gi, '');
  }
}

export default downloadSubtitle;
