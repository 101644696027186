import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'components/Explorer/Transition';
import { map, sumBy } from 'lodash-es';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';

import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import NumberHelper from 'util/number_helper';

highchartsAccessibility(Highcharts);

class Distribution extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  state = {
    loading: true,
    loadingFailed: false,
    data: {}
  };

  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Highlights-Distribution Highlights-block">
        <h2 className="Highlights-title">{I18n.t('Explorer.Highlights.Distribution.title')}</h2>

        <div className="Highlights-content-wrapper">
          <Transition>{this.state.loading ? this.loadingMessage : this.content}</Transition>
        </div>
      </div>
    );
  }

  get loadingMessage() {
    return <div key="loadingMessage" className="Highlights-loading-message" />;
  }

  get content() {
    if (this.state.loadingFailed) return this.errorMessage;

    return (
      <div key="content" className="Highlights-content Highlights-Distribution-content">
        {this.hasResults ? this.chart : this.emptyMessage}
      </div>
    );
  }

  get errorMessage() {
    return <div className="Highlights-error-message">{I18n.t('Explorer.loading_error')}</div>;
  }

  get chart() {
    return <HighchartsReact highcharts={Highcharts} options={this.chartConfig} />;
  }

  get emptyMessage() {
    return <div className="Highlights-content-empty-message">{I18n.t('Explorer.Highlights.Distribution.empty')}</div>;
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true, loadingFailed: false });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.highlightsDistribution();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let data = null;
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        data = response.body;
      }

      this.setState({ loading: false, loadingFailed, data });
    });
  };

  // Chart
  //////////////////////////////////////////////////////////////////////////////

  get chartConfig() {
    return {
      credits: false,
      title: false,
      chart: {
        type: 'column',
        style: {
          fontFamily: '"opensans-webfont", Helvetica, Arial'
        },
        height: 300,
        width: 500
      },
      yAxis: [
        {
          type: 'logarithmic',
          title: {
            text: I18n.t('Explorer.Highlights.Distribution.count_axis_label')
          }
        }
      ],
      xAxis: {
        type: 'category',
        title: {
          text: I18n.t('Explorer.Highlights.Distribution.bucket_axis_label')
        }
      },
      plotOptions: {
        column: {
          groupPadding: 0.02
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        formatter: this.tooltipFormatter
      },
      series: this.seriesData
    };
  }

  get seriesData() {
    return [
      {
        name: I18n.t('Explorer.Highlights.Distribution.research_outputs'),
        color: '#1565c0',
        data: this.data
      }
    ];
  }

  get data() {
    return map(this.state.data, (bucket, index) => {
      let labelKey = index === this.state.data.length - 1 ? 'overflow_bucket_label' : 'bucket_label';
      let name = I18n.t(labelKey, {
        scope: 'Explorer.Highlights.Distribution',
        ...bucket
      });

      return {
        name: name,
        y: bucket.count
      };
    });
  }

  tooltipFormatter() {
    return I18n.t('Explorer.Highlights.Distribution.chart_tooltip', {
      count: this.y,
      formattedCount: NumberHelper.formatNumberWithDelimiter(this.y),
      range: this.point.name
    });
  }

  get hasResults() {
    return this.state.data && sumBy(this.state.data, 'count');
  }
}

export default withRouter(Distribution);
