import './styles';

import React from 'react';
import PropTypes from 'prop-types';

import withRouter from 'components/withRouter';
import Transition from 'components/Explorer/Transition';

import Query from 'components/Explorer/models/query';
import Spinner from 'components/Explorer/Spinner';
import PageTitle from 'components/Explorer/PageTitle';
import Pagination from 'components/Explorer/Pagination';

import FilterPanel from './FilterPanel';
import MentionSource from './MentionSource';
import SortBy from './SortBy';
import Summary from './Summary';

class MentionSources extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////
  rootRef = React.createRef();

  state = {
    loading: true,
    loadingFailed: false,
    forbidden: false,
    data: [],
    lastPage: true
  };

  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(
      this.fetchData,
      Query.EVENTS.didChangeFilters,
      Query.EVENTS.didChangePage,
      Query.EVENTS.didChangeMentionSourceOrder
    );

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div ref={this.rootRef} className="Explorer-MentionSources">
        <PageTitle metaTitle={I18n.t('Explorer.TabBar.mention_sources')} />
        <FilterPanel />
        <Summary />

        <Transition>{this.state.loading ? this.loadingMessage : this.content}</Transition>
      </div>
    );
  }

  get loadingMessage() {
    return (
      <div key="content" className="Explorer-MentionSources-Container">
        <Spinner key="spinner" />
      </div>
    );
  }

  get content() {
    if (this.state.loadingFailed) return this.errorMessage;

    return (
      <div key="content" className="Explorer-MentionSources-Container">
        <SortBy />
        <Pagination lastPage={this.state.lastPage} onChangePage={this.trackPageChange} />
        {this.hasResults ? this.mentionSources : this.emptyMessage}
        <Pagination lastPage={this.state.lastPage} onChangePage={this.trackPageChange} />
      </div>
    );
  }

  get errorMessage() {
    return (
      <div key="content" className="Explorer-MentionSources-Container">
        {this.state.forbidden ? I18n.t('Explorer.forbidden_error') : I18n.t('Explorer.loading_error')}
      </div>
    );
  }

  get mentionSources() {
    return (
      <div>
        {this.state.data.map((mentionSource) => (
          <MentionSource mentionSource={mentionSource} key={mentionSource.id} />
        ))}
      </div>
    );
  }

  get emptyMessage() {
    return <div className="Explorer-MentionSources-empty-message">{I18n.t('Explorer.MentionSources.empty')}</div>;
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true, loadingFailed: false, forbidden: false });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.mentionSources();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let data = null,
        lastPage = true;
      const forbidden = response?.status === 403;
      const loadingFailed = err || typeof response?.body === 'undefined';

      if (!loadingFailed) {
        data = response.body.data;
        lastPage = response.body.lastPage;
      }

      this.setState({ loading: false, loadingFailed, forbidden, data, lastPage }, this.scrollToTop);
    });
  };

  get hasResults() {
    return this.state.data && this.state.data.length > 0;
  }

  scrollToTop = () => {
    const root = this.rootRef.current;
    const wrapper = root.closest('.Explorer-ContentPanel');
    if (wrapper) wrapper.scrollTop = 0;
  };

  trackPageChange = (page) => {
    Analytics.trackEvent('paginate-mention-author', { page });
  };
}

export default withRouter(MentionSources);
