import React from 'react';
import { createRoot } from 'react-dom/client';

import ApplicationLoader from '../components/ApplicationLoader';

const setup = function () {
  window.addEventListener('DOMContentLoaded', showReactApplication);
};

const showReactApplication = function () {
  const appComponentWrapper = document.getElementById('application-wrapper');
  if (!appComponentWrapper) return;

  const root = createRoot(appComponentWrapper);
  root.render(React.createElement(ApplicationLoader, appComponentWrapper.dataset));
};

export { setup };
