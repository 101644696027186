import $ from 'jquery';
import { defer, delay } from 'lodash-es';
import ClipboardJS from 'clipboard';

import { createRoot } from 'react-dom/client';

import { HistoricalDataset } from '../models/historical_dataset';
import Ajax from '../util/ajax';
import { renderReloadableComponent } from '../modules/react_helper';

import { DatasetUploadPopover } from '../components/DatasetUploadPopover';
import { DatasetPublishProgressPanel } from '../components/DatasetPublishProgressPanel';

const setup = function () {
  $(document).on('click', '.invite-form .copy-button', () => Analytics.trackEvent('shareSignupURL'));

  $(document).on('click', '.csv-upload-form .upload-csv-cta', () => {
    Analytics.trackEvent('uploadCSV');
    showUploadPopover();
  });

  $(document).on('click', '.csv-upload-form .publish_existing_dataset', function () {
    Analytics.trackEvent('publishExistingDataset');
    publishExistingDataset($(this).data('id'));
  });

  window.addEventListener('DOMContentLoaded', setupClipboardHandler);
};

const setupClipboardHandler = function () {
  let $inviteForm = $('.invite-form');
  if (!$inviteForm.length) return;

  let $button = $inviteForm.find('.copy-button');

  new ClipboardJS($button[0])
    .on('success', () => displayTooltip($button, I18n.t('admin_panel.invite.copied')))
    .on('error', () => displayTooltip($button, I18n.t('admin_panel.invite.manual_copy')));
};

const displayTooltip = function ($button, text) {
  let $tooltip = $('<div class="mini-tooltip"/>').text(text);
  $button.before($tooltip);
  defer(() => $tooltip.addClass('visible'));

  delay(() => {
    $tooltip.removeClass('visible');
    delay(() => $tooltip.remove(), 1000);
  }, 1000);
};

let uploadPopoverRoot = null;
let publishExistingPopoverRoot = null;
let activelyPublishingDataset = null;

const hidePublishingPopovers = function () {
  if (uploadPopoverRoot) {
    uploadPopoverRoot.unmount();
    uploadPopoverRoot = null;
  }

  if (publishExistingPopoverRoot) {
    publishExistingPopoverRoot.unmount();
    publishExistingPopoverRoot = null;
    activelyPublishingDataset = null;
  }
};

const showUploadPopover = function () {
  uploadPopoverRoot = createRoot(document.querySelector('.dataset-upload-panel-wrapper'));
  renderReloadableComponent(DatasetUploadPopover, uploadPopoverRoot);
};

const publishExistingDataset = function (id) {
  activelyPublishingDataset = new HistoricalDataset(id);

  publishExistingPopoverRoot = createRoot(document.querySelector('.dataset-publish-existing-panel-wrapper'));
  renderReloadableComponent(DatasetPublishProgressPanel, publishExistingPopoverRoot, {
    dataset: activelyPublishingDataset,
    showCloseButton: true
  });
};

const refreshCSVPanel = () => {
  Ajax.getCSVIntegrationPanel().done((data) => $('.csv-upload-form').replaceWith(data));
};

if (module.hot) {
  module.hot.accept(require.resolve('../components/DatasetPublishProgressPanel'), () => {
    if (publishExistingPopoverRoot) {
      let component = require('../components/DatasetPublishProgressPanel').DatasetPublishProgressPanel;
      renderReloadableComponent(component, publishExistingPopoverRoot, {
        dataset: activelyPublishingDataset,
        showCloseButton: true
      });
    }
  });

  module.hot.accept(require.resolve('../components/DatasetUploadPopover'), () => {
    if (uploadPopoverRoot) {
      let component = require('../components/DatasetUploadPopover').DatasetUploadPopover;
      renderReloadableComponent(component, uploadPopoverRoot);
    }
  });
}

export { setup, hidePublishingPopovers, refreshCSVPanel };
