import './styles';

import { map, uniq } from 'lodash-es';

import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'components/Explorer/Transition';
import withRouter from 'components/withRouter';

import PageTitle from 'components/Explorer/PageTitle';
import Pagination from 'components/Explorer/Pagination';
import Query from 'components/Explorer/models/query';
import Spinner from 'components/Explorer/Spinner';
import Post from './Post';
import Summary from './Summary';
import FilterPanel from './FilterPanel';

const Loading = {
  IN_PROGRESS: 'in-progress',
  ERROR: 'error',
  SUCCESS: 'success'
};

class Mentions extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////
  rootRef = React.createRef();

  query = null;

  state = {
    loading: Loading.IN_PROGRESS,
    data: []
  };

  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters, Query.EVENTS.didChangePage);
    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div ref={this.rootRef} className="Explorer-Mentions">
        <PageTitle metaTitle={I18n.t('Explorer.TabBar.mentions')} />
        <FilterPanel />
        <a
          href={I18n.t('Explorer.help.mentions.uri')}
          target="_blank"
          rel="noreferrer"
          title={I18n.t('Explorer.help.mentions.content')}
          className="help"
        >
          {I18n.t('Explorer.help.label_tab')}
        </a>
        <Summary />
        <Transition classNames="Content" timeout={750}>
          <>
            {this.state.loading === Loading.IN_PROGRESS && <Spinner key="spinner" />}
            {this.state.loading === Loading.ERROR && (
              <div className="Explorer-Mentions-Container">{I18n.t('Explorer.loading_error')}</div>
            )}
            {this.state.loading === Loading.SUCCESS && this.state.data.length !== 0 && (
              <div className="Explorer-Mentions-Container">
                <Pagination lastPage={this.state.lastPage} onChangePage={this.trackPageChange} />
                <div className="mention-timeline">
                  {this.state.data.map(([date, posts]) => {
                    const uniqPosts = uniq(posts);

                    return (
                      <div className="mention-date-boundary" key={`mentions-${date}`}>
                        {map(uniqPosts, (post, index) => (
                          <Post first={index === 0} post={post} key={post.id} />
                        ))}
                      </div>
                    );
                  })}
                </div>
                <Pagination lastPage={this.state.lastPage} onChangePage={this.trackPageChange} />
              </div>
            )}
          </>
        </Transition>
      </div>
    );
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: Loading.IN_PROGRESS });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.mentions();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (err || typeof response.body === 'undefined') {
        this.setState({ loading: Loading.ERROR, data: null, lastPage: null }, this.scrollToTop);
      } else {
        const { data, lastPage } = response.body;
        this.setState({ loading: Loading.SUCCESS, data, lastPage }, this.scrollToTop);
      }
    });
  };

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  scrollToTop = () => {
    const root = this.rootRef.current;
    const wrapper = root.closest('.Explorer-ContentPanel');
    if (wrapper) wrapper.scrollTop = 0;
  };

  trackPageChange = (page) => {
    Analytics.trackEvent('mentions-paginate', { page });
  };
}

export default withRouter(Mentions);
