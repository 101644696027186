import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'components/Explorer/Transition';
import withRouter from 'components/withRouter';
import { some, sumBy } from 'lodash-es';

import Category from './category';
import LabelCategory from './label_category';
import ChartCategory from './chart_category';
import InspectorCategory from './InspectorCategory';

import Query from 'components/Explorer/models/query';

const DEFAULT_BACKGROUND = '#eceff1';

class Breakdown extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  state = {
    loading: true,
    loadingFailed: false,
    categories: [],
    categoryRefs: [],
    inspecting: null,
    mouseOver: false
  };

  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);
    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Highlights-Breakdown Highlights-block">
        <h2 className="Highlights-title">{I18n.t('Explorer.Highlights.Breakdown.title')}</h2>

        <div className="Highlights-content-wrapper">
          <Transition>{this.state.loading ? this.loadingMessage : this.content}</Transition>
        </div>
      </div>
    );
  }

  get loadingMessage() {
    return <div key="loadingMessage" className="Highlights-loading-message" />;
  }

  get content() {
    if (this.state.loadingFailed) return this.errorMessage;

    return (
      <div
        key="content"
        className={`Highlights-content Highlights-Breakdown-content ${
          this.state.mouseOver ? 'hide-focus-outline' : ''
        }`}
        data-testid="content"
        onMouseLeave={this.unInspectCategory}
      >
        {this.hasResults ? this.chartWithLabels : this.emptyMessage}
      </div>
    );
  }

  get chartWithLabels() {
    return (
      <div>
        {this.chart}
        {this.labels}
      </div>
    );
  }

  get errorMessage() {
    return <div className="Highlights-error-message">{I18n.t('Explorer.loading_error')}</div>;
  }

  get emptyMessage() {
    return <div className="Highlights-content-empty-message">{I18n.t('Explorer.Highlights.Breakdown.empty')}</div>;
  }

  get chart() {
    const style = {
      backgroundColor: this.state.inspecting ? this.state.inspecting.color : DEFAULT_BACKGROUND
    };

    return (
      <div className="Highlights-Breakdown-chart">
        <div className={`background ${this.state.inspecting ? 'inspecting' : 'not-inspecting'}`} style={style} />
        {this.state.inspecting ? this.inspector : null}
        {this.chartCategories}
      </div>
    );
  }

  get labels() {
    return (
      <div className="Highlights-Breakdown-labels">
        {this.state.categories.map((c) => {
          const ref = this.state.categoryRefs.find((cr) => cr.name === c.name).ref;
          return <LabelCategory key={c.name} category={c} onClick={() => this.inspectCategory(c)} ref={ref} />;
        })}
      </div>
    );
  }

  get inspector() {
    const totalMentions = sumBy(this.state.categories, 'mentionCount');
    const { name, mentionCount, sources } = this.state.inspecting;

    return (
      <div className="Highlights-Breakdown-inspector">
        {
          <InspectorCategory
            categoryName={I18n.t(name, {
              scope: 'Explorer.Highlights.Breakdown.categories'
            })}
            categoryMentionCount={mentionCount}
            categorySources={sources}
            totalMentions={totalMentions}
            onCloseInspector={this.handleCloseInspector}
          />
        }
      </div>
    );
  }

  get chartCategories() {
    const totalMentions = sumBy(this.state.categories, 'scaledMentionCount');

    return (
      <div className="categories">
        {this.state.categories.map((c) => {
          return (
            <ChartCategory
              key={c.name}
              category={c}
              totalMentions={totalMentions}
              inspecting={this.state.inspecting === c}
              onInspect={() => this.inspectCategory(c, true)}
            />
          );
        })}
      </div>
    );
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true, loadingFailed: false });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.highlightsBreakdown();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let categories = null;
      let categoryRefs = null;
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        categories = Category.buildFromData(response.body);
        categoryRefs = categories.map((c) => {
          return {
            name: c.name,
            ref: React.createRef()
          };
        });
      }

      this.setState({ loading: false, loadingFailed, categories, categoryRefs });
    });
  };

  // Events
  //////////////////////////////////////////////////////////////////////////////

  handleCloseInspector = () => {
    const category = this.state.inspecting;
    this.unInspectCategory();

    this.state.categoryRefs.find((cr) => cr.name === category.name).ref.current.focus();
  };

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  inspectCategory = (category, mouseOver = false) => {
    Analytics.trackEvent('highlights-breakdown-inspect-category', {
      category: category.name
    });
    this.setState({ inspecting: category, mouseOver });
  };

  unInspectCategory = () => {
    this.setState({ inspecting: null, mouseOver: false });
  };

  get hasResults() {
    return this.state.categories && some(this.state.categories, 'mentionCount');
  }
}

export default withRouter(Breakdown);
