import React from 'react';
import PropTypes from 'prop-types';

import Category from './category';

const ChartCategory = (props) => {
  const category = props.category;
  const totalMentions = props.totalMentions;
  const percentOfTotal = (category.scaledMentionCount / totalMentions) * 100;

  const style = {
    width: `${percentOfTotal}%`,
    backgroundColor: category.color
  };

  return (
    <div
      className={`category ${props.inspecting ? 'inspecting' : 'not-inspecting'}`}
      key={category.name}
      style={style}
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      onMouseOver={props.onInspect}
      data-testid={category.name}
    />
  );
};

ChartCategory.propTypes = {
  category: PropTypes.instanceOf(Category).isRequired,
  totalMentions: PropTypes.number.isRequired,
  inspecting: PropTypes.bool.isRequired,
  onInspect: PropTypes.func.isRequired
};

export default ChartCategory;
