import './styles';

import { debounce, filter, forEach, map, merge, sum, template } from 'lodash-es';

import React from 'react';
import withRouter from 'components/withRouter';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import highchartsExporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import dayjs from 'util/date';
import NumberHelper from 'util/number_helper';

import config from 'util/site_config.js.erb';
import downloadImageCredit from 'components/Explorer/util/downloadImageCredit';
import downloadSubtitle from 'components/Explorer/util/downloadSubtitle';
import Query from 'components/Explorer/models/query';

highchartsAccessibility(Highcharts);
highchartsExporting(Highcharts);
OfflineExporting(Highcharts);

const ANALYTICS_DEBOUNCE_TIME = 1000; //ms

class Chart extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    data: PropTypes.array.isRequired,
    selectedPostTypes: PropTypes.array.isRequired,
    latestChartDate: PropTypes.object.isRequired,
    totalOutputs: PropTypes.number.isRequired,
    queryDescription: PropTypes.string.isRequired
  };

  // These series colors need to match up with the
  // sources in sources.yml, the order is defined
  // by the `position` value.
  //
  // Note: There is no series color for video as
  // video is an excluded source,
  // see `exclusionary_flag` in sources.yml
  static SERIES_COLORS = [
    '#B60000', // 1 msm (news)
    '#e89500', // 2 blog
    '#270a63', // 3 policy
    '#d45029', // 4 patent
    '#2F90B9', // 5 tweet (X)
    '#bdbdbd', // 6 peer_review
    '#df931b', // 7 weibo
    '#071D70', // 8 fbwall
    '#3b2a3d', // 9 wikipedia
    '#912470', // 10 gplus
    '#00BFFF', // 11 linkedin
    '#B9DDEB', // 12 rdt
    '#CC3300', // 13 pinterest
    '#CB2D2D', // 14 f1000
    '#EFEFEF', // 15 qna
    '#15BABC', // 16 guideline
    '#1185FE' // 17 bluesky
  ];

  static CHART_MIN_RANGE = 60 * 60 * 24 * 7 * 1000; // 7 days
  static CHART_MIN_TICK = 60 * 60 * 24 * 1000; // 1 day

  static tooltipTemplate = template(
    `<table>
      <tr class="header">
        <td colspan="3"><%= I18n.t("Explorer.Timeline.chart.tooltip_title") %> <%= date %></td>
      </tr>
      <% forEach(types, function(type) { %>
        <tr>
          <td class="count"><%= Highcharts.numberFormat(type.y, 0) %></td>
          <td class="symbol" style="color:<%= type.series.color %>">●</td>
          <td class="label">
            <%= I18n.t(type.series.userOptions.id, {scope: "Explorer.Timeline.chart.types", count: total}) %>
            <%= I18n.t("Explorer.Timeline.chart.legend.mentions", { count: total }) %>
          </td>
        </tr>
      <% }); %>
      <% if (types.length > 1) { %>
        <tr class="footer">
          <td class="count"><%= Highcharts.numberFormat(total, 0) %></td>
          <td colspan="2" class="label"><%= I18n.t("Explorer.Timeline.chart.total", {count: total}) %></td>
        </tr>
      <% } %>
    </table>`,
    { imports: { Highcharts, forEach } }
  );

  // Setup
  //////////////////////////////////////////////////////////////////////////////

  chartRef = null;

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);

    this.query.registerCallback(this.updateTimeframe, Query.EVENTS.didChangeFilters);

    this.updateTimeframe();

    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.selectedPostTypes != nextProps.selectedPostTypes) {
      this.updateDisplayedSources(nextProps);
    }

    return this.props.data != nextProps.data || this.state.timeframe != nextState.timeframe;
  }

  updateDisplayedSources(props) {
    if (!this.chartRef) return;

    const chart = this.chartRef;
    chart.series.forEach((s) => {
      const selected =
        props.selectedPostTypes.length === 0 ||
        s.name === 'Navigator' ||
        props.selectedPostTypes.indexOf(s.userOptions.id) > -1;

      s.setVisible(selected, false);
    });

    chart.redraw();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={this.config}
        constructorType={'stockChart'}
        callback={this.assignRef}
        containerProps={{ 'data-testid': 'chart' }}
      />
    );
  }

  assignRef = (ref) => {
    this.chartRef = ref;

    this.updateDisplayedSources(this.props);
  };

  // Actions
  //////////////////////////////////////////////////////////////////////////////

  updateTimeframe = () => {
    this.setState({ timeframe: this.timeframe });
  };

  showTimePointMentions = (e, context) => {
    const startDate = dayjs.utc(e.point.x);
    let endDate = startDate;

    if (context.currentDataGrouping) {
      endDate = dayjs
        .utc(e.point.x)
        .add(context.currentDataGrouping.count, context.currentDataGrouping.unitName)
        .subtract(1, 'day');
    }

    const filters = {
      mentioned_after: startDate.format('YYYY-MM-DD'),
      mentioned_before: endDate.format('YYYY-MM-DD')
    };

    if (this.props.selectedPostTypes.length > 0) {
      filters['mention_sources[]'] = this.props.selectedPostTypes.map((type) => `type:${type}`);
    }

    Analytics.trackEvent('timeline-click-bar', filters);

    this.props.history.push(
      this.query.withFilters(merge(this.query.filters, filters)).locationWithPathname('/mentions')
    );
  };

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  get config() {
    return {
      chart: {
        height: 550,
        type: 'column',
        style: {
          fontFamily: '"opensans-webfont", Helvetica, Arial, sans-serif'
        }
      },
      colors: Chart.SERIES_COLORS,
      credits: { text: downloadImageCredit() },
      exporting: this.exportingConfig,
      tooltip: this.tooltipConfig,
      plotOptions: this.plotOptionsConfig,
      xAxis: this.xAxisConfig,
      yAxis: this.yAxisConfig,
      rangeSelector: this.rangeSelectorConfig,
      navigator: this.navigatorConfig,
      series: this.dataForChart
    };
  }

  get exportingConfig() {
    return {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['downloadPNG']
        }
      },
      filename: this.filename,
      chartOptions: {
        legend: { enabled: true },
        navigator: { enabled: false },
        rangeSelector: { enabled: false },
        scrollbar: { enabled: false },
        title: {
          text: 'Altmetric Explorer Timeline'
        },
        subtitle: {
          text: downloadSubtitle(this.props.queryDescription, true)
        },
        caption: {
          text: this.summaryDescription,
          style: {
            fontSize: 12
          }
        },
        chart: {
          spacing: [26, 26, 31, 26]
        },
        yAxis: {
          title: {
            text: 'Mentions'
          }
        }
      }
    };
  }

  get tooltipConfig() {
    return {
      shadow: false,
      backgroundColor: 'rgba(245, 245, 255, 0.95)',
      headerFormat: '{point.key}',
      style: {
        lineHeight: '17px',
        padding: '13px'
      },
      borderWidth: 1,
      borderRadius: 3,
      borderColor: 'rgba(235, 235, 245, 1)',
      useHTML: true,
      shared: true,
      formatter: function (tooltip) {
        const item = this.points[0];
        const date = tooltip.tooltipFooterHeaderFormatter(item);
        const total = item.total;

        return Chart.tooltipTemplate({
          date: date,
          types: filter(this.points, (p) => p.y),
          total: total
        });
      }
    };
  }

  get plotOptionsConfig() {
    const reactContext = this;

    return {
      column: {
        stacking: 'normal',
        pointPadding: 0,
        groupPadding: 0.05,
        cursor: 'pointer',
        dataGrouping: {
          approximation: 'sum',
          groupPixelWidth: 30,
          forced: true,
          units: [
            ['day', [1]],
            ['week', [1]],
            ['month', [1]],
            ['year', [1]]
          ]
        },
        events: {
          click: function (e) {
            return reactContext.showTimePointMentions(e, this);
          }
        }
      }
    };
  }

  get xAxisConfig() {
    const minMax = this.state.timeframe || {};

    return {
      ordinal: false,
      minRange: Chart.CHART_MIN_RANGE,
      minTickInterval: Chart.CHART_MIN_TICK,
      events: {
        setExtremes: this.trackZoom
      },
      labels: {
        style: { color: '#333333', cursor: 'default', fontSize: '11px' }
      },
      ...minMax
    };
  }

  get yAxisConfig() {
    return {
      minTickInterval: 1,
      allowDecimals: false,
      offset: 40,
      labels: {
        style: { color: '#333333', cursor: 'default', fontSize: '11px' }
      }
    };
  }

  get rangeSelectorConfig() {
    return {
      inputEnabled: false,
      buttonSpacing: 5,
      buttonTheme: {
        width: 100,
        r: 3,
        fill: '#E5E7F2',
        states: {
          hover: { fill: '#F2F4FA' },
          select: {
            fill: '#1565c0',
            style: { color: '#FFFFFF' }
          },
          disabled: {
            style: {
              cursor: 'not-allowed'
            }
          }
        }
      },
      buttons: [
        {
          type: 'week',
          count: 1,
          text: I18n.t('Explorer.Timeline.chart.zoom.week')
        },
        {
          type: 'month',
          count: 1,
          text: I18n.t('Explorer.Timeline.chart.zoom.one_month')
        },
        {
          type: 'month',
          count: 3,
          text: I18n.t('Explorer.Timeline.chart.zoom.three_months')
        },
        {
          type: 'month',
          count: 6,
          text: I18n.t('Explorer.Timeline.chart.zoom.six_months')
        },
        {
          type: 'year',
          count: 1,
          text: I18n.t('Explorer.Timeline.chart.zoom.one_year')
        },
        {
          type: 'all',
          text: I18n.t('Explorer.Timeline.chart.zoom.all_time')
        }
      ],
      selected: this.state.timeframe ? null : 5
    };
  }

  get navigatorConfig() {
    return {
      maskFill: 'rgba(28, 164, 244, 0.3)',
      series: {
        lineColor: '#1CA4F4',
        fillOpacity: 0,
        dataGrouping: {
          approximation: 'sum'
        },
        data: this.dataForNavigation
      }
    };
  }

  get dataForChart() {
    return map(config.postTypes, (type, index) => {
      return {
        id: type,
        name: I18n.t(type, {
          scope: 'Explorer.Timeline.chart.types'
        }),
        data: map(this.props.data, (row) => [row[0], row[index + 1]])
      };
    });
  }

  get dataForNavigation() {
    return map(this.props.data, (row) => {
      const [timestamp, ...rowData] = row;

      return [timestamp, sum(rowData)];
    });
  }

  get timeframe() {
    const tf = this.query.filters.timeframe;
    if (!tf || tf === 'at') return;

    const units = {
      d: 'day',
      w: 'week',
      m: 'month',
      y: 'year'
    };
    const [timespan, unit] = tf;
    const startTime = dayjs().startOf('day').subtract(parseInt(timespan), units[unit]);
    const endTime = this.props.latestChartDate.startOf('day');

    return {
      min: startTime.valueOf(),
      max: endTime.valueOf()
    };
  }

  get summaryDescription() {
    return I18n.t('Explorer.Timeline.Summary.research_outputs', {
      count: this.props.totalOutputs,
      formattedCount: NumberHelper.formatNumberWithDelimiter(this.props.totalOutputs)
    });
  }

  get filename() {
    return `Altmetric Explorer Timeline - ${downloadSubtitle(this.props.queryDescription, false)}`;
  }

  trackZoom = (e) => {
    this.debouncedTrackZoom(e);
  };

  debouncedTrackZoom = debounce((e) => {
    const payload = {
      trigger: e.trigger,
      min: e.min,
      max: e.max
    };

    Analytics.trackEvent('timeline-chart-zoom', payload);
  }, ANALYTICS_DEBOUNCE_TIME);
}

export default withRouter(Chart);
