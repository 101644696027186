import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import HighmapsWorldData from 'vendor/highmaps-world';
import highchartsExporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import downloadImageCredit from 'components/Explorer/util/downloadImageCredit';
import downloadSubtitle from 'components/Explorer/util/downloadSubtitle';
import NumberHelper from 'util/number_helper';

import Tabs from './Tabs';

import { template } from 'lodash-es';

highchartsExporting(Highcharts);
OfflineExporting(Highcharts);

const tooltipTemplate = template(
  `
  <table>
    <tr class="header">
      <th colspan="3"><%= title %></th>
    </tr>
    <tr>
      <td class="count"><%= Highcharts.numberFormat(point.value, 0) %></td>
      <td class="percent"><%= Highcharts.numberFormat(point.percent_posts, 1) %>%</td>
      <td class="label"><%= I18n.t("Explorer.Demographics.types." + post_type + ".map_labels.posts", {count: point.value}) %></td>
    </tr>
    <tr>
      <td class="count"><%= Highcharts.numberFormat(point.profiles, 0) %></td>
      <td class="percent"><%= Highcharts.numberFormat(point.percent_profiles, 1) %>%</td>
      <td class="label"><%= I18n.t("Explorer.Demographics.types." + post_type + ".map_labels.profiles", {count: point.profiles}) %></td>
    </tr>
  </table>
`,
  { imports: { Highcharts: Highcharts } }
);

class Map extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    totals: PropTypes.object.isRequired,
    selectedSource: PropTypes.oneOf(Tabs.TYPES).isRequired,
    onViewMentions: PropTypes.func.isRequired,
    queryDescription: PropTypes.string.isRequired
  };

  static COLORS = {
    tweet: {
      minColor: '#F2F4FA',
      maxColor: '#0086D6',
      hover: '#FF0081'
    },
    msm: {
      minColor: '#F6CCCC',
      maxColor: '#D60000',
      hover: '#FBFB00'
    },
    policy: {
      minColor: '#EBF5F7',
      maxColor: '#9D00FF',
      hover: '#FF9025'
    },
    fbwall: {
      minColor: '#D3E9F3',
      maxColor: '#071D70',
      hover: '#F53955'
    },
    guideline: {
      minColor: '#A1E3E4',
      maxColor: '#108D8F',
      hover: '#FFDB58'
    }
  };

  render() {
    return (
      <div className="Explorer-Demographics-Map">
        <HighchartsReact
          allowChartUpdate={false}
          highcharts={Highcharts}
          options={this.config}
          constructorType={'mapChart'}
        />
      </div>
    );
  }

  get config() {
    const selectedSource = this.props.selectedSource;

    return {
      credits: { text: downloadImageCredit(), mapText: '' },
      chart: {
        height: '600px',
        backgroundColor: 'white',
        margin: [0, 0, 0, 0]
      },
      colorAxis: {
        type: 'logarithmic',
        minColor: this.colorMap.minColor,
        maxColor: this.colorMap.maxColor
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: ['downloadPNG']
          }
        },
        filename: this.filename,
        chartOptions: {
          chart: {
            spacing: [50, 50, 50, 50]
          },
          legend: { enabled: true },
          navigator: { enabled: false },
          title: {
            text: this.title
          },
          subtitle: {
            text: downloadSubtitle(this.props.queryDescription, true)
          },
          caption: {
            text: this.summaryDescription,
            style: {
              fontSize: 12
            }
          }
        }
      },
      title: false,
      mapNavigation: {
        enabled: true,
        enableMouseWheelZoom: false
      },
      tooltip: {
        shadow: false,
        backgroundColor: 'white',
        style: {
          lineHeight: '17px',
          padding: '13px'
        },
        borderWidth: 1,
        borderRadius: 3,
        borderColor: 'rgba(235, 235, 245, 1)',
        useHTML: true,
        formatter: function () {
          return tooltipTemplate({
            title: this.key,
            point: this.point,
            post_type: selectedSource
          });
        }
      },
      series: [
        {
          data: this.data,
          mapData: HighmapsWorldData,
          joinBy: ['iso-a2', 'code'],
          name: 'Tweets',
          states: {
            hover: {
              color: this.colorMap.hover,
              borderWidth: 1
            }
          }
        }
      ],
      plotOptions: {
        series: {
          cursor: 'pointer',
          events: {
            click: this.openMentions
          }
        }
      }
    };
  }

  get colorMap() {
    return Map.COLORS[this.props.selectedSource];
  }

  get data() {
    return this.props.data.map((d) => ({
      code: d.code,
      value: d.posts,
      profiles: d.profiles,
      percent_posts: d.percent_posts,
      percent_profiles: d.percent_profiles
    }));
  }

  get summaryDescription() {
    return `${this.summaryPart('posts')} ${this.summaryPart('profiles')} ${this.summaryPart('countries')}`;
  }

  summaryPart(name) {
    return I18n.t(`Explorer.Demographics.types.${this.props.selectedSource}.counts.${name}`, {
      count: this.props.totals[name],
      formattedCount: NumberHelper.formatNumberWithDelimiter(this.props.totals[name])
    });
  }

  get title() {
    return `${I18n.t(`Explorer.Demographics.types.${this.props.selectedSource}.title`)} Demographics from Altmetric Explorer`;
  }

  get filename() {
    return `${this.title} - ${downloadSubtitle(this.props.queryDescription, false)}`;
  }

  openMentions = (e) => {
    this.props.onViewMentions(e.point.code, this.props.selectedSource);
  };
}

export default Map;
