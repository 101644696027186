import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const Transition = ({ children, classNames = 'fade', timeout = 250 }) => {
  const nodeRef = React.createRef();

  return (
    <TransitionGroup>
      {children && (
        <CSSTransition nodeRef={nodeRef} key={children.key} classNames={classNames} timeout={timeout}>
          <div ref={nodeRef} className="Transition" style={{ '--transition-duration': `${timeout}ms` }}>
            {children}
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

export default Transition;
