import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';
import { cloneDeep } from 'lodash-es';

import PageTitle from 'components/Explorer/PageTitle';
import Query from 'components/Explorer/models/query';

import Tabs from './Tabs';
import Summary from './Summary';
import Map from './Map';
import Table from './Table';

class Demographics extends React.Component {
  query = null;

  state = {
    loading: true,
    loadingFailed: false,
    data: {},
    selectedSource: Tabs.TYPES[0]
  };

  static propTypes = {
    history: PropTypes.object
  };

  componentDidMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);
    this.fetchData();

    this.setState({
      selectedSource: this.query.filters.filter || Tabs.TYPES[0]
    });
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Explorer-Demographics">
        <PageTitle metaTitle={I18n.t('Explorer.TabBar.demographics')} />
        <Tabs selectedSource={this.state.selectedSource} onChange={this.setSource} />
        <div className="Explorer-Demographics-Container">{this.state.loading ? this.loadingMessage : this.content}</div>
      </div>
    );
  }

  get content() {
    if (this.state.loadingFailed) {
      return this.errorMessage;
    } else if (this.state.data.countries.length) {
      return this.populatedContent;
    } else {
      return this.emptyMessage;
    }
  }

  get populatedContent() {
    return (
      <div className="Explorer-Demographics-content">
        {this.help}
        <Summary data={this.state.data.total} selectedSource={this.state.selectedSource} />
        <div className="Explorer-Demographics-content-data">
          <Map
            data={this.state.data.countries}
            totals={this.state.data.total}
            selectedSource={this.state.selectedSource}
            onViewMentions={this.openMentions}
            queryDescription={this.state.data.query_description}
          />
          <Table
            data={this.state.data.countries}
            selectedSource={this.state.selectedSource}
            locationBuilder={this.locationForFilter}
          />
        </div>
      </div>
    );
  }

  get help() {
    return (
      <a
        href={I18n.t('Explorer.help.demographics.uri')}
        target="_blank"
        rel="noreferrer"
        title={I18n.t('Explorer.help.demographics.content')}
        className="help"
      >
        {I18n.t('Explorer.help.label_tab')}
      </a>
    );
  }

  get emptyMessage() {
    return <div className="Explorer-Demographics-empty-message">{I18n.t('Explorer.Demographics.empty')}</div>;
  }

  get errorMessage() {
    return <div className="Explorer-Demographics-empty-message">{I18n.t('Explorer.loading_error')}</div>;
  }

  get loadingMessage() {
    return <div className="Explorer-Demographics-loading-message">{I18n.t('Explorer.Demographics.loading')}</div>;
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true, loadingFailed: false });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.demographics();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      const loadingFailed = err || typeof response.body === 'undefined';
      this.setState({ loading: false, loadingFailed, data: response.body });
    });
  };

  setSource = (filter) => {
    this.setState({ selectedSource: filter });

    const newFilters = cloneDeep(this.query.filters);

    if (filter === Tabs.TYPES[0]) {
      delete newFilters.filter;
    } else {
      newFilters.filter = filter;
    }

    Analytics.trackEvent('demographics-set-source', { filter });

    this.props.history.replace(this.query.withFilters(newFilters).location);
  };

  openMentions = (countryCode, source) => {
    Analytics.trackEvent('demographics-click-map', {
      country: countryCode,
      source: source
    });

    this.props.history.replace(this.locationForFilter(countryCode, source));
  };

  locationForFilter = (countryCode, source) => {
    const newFilters = cloneDeep(this.query.filters);
    newFilters['countries[]'] = countryCode;
    newFilters['mention_sources[]'] = `type:${source}`;

    return this.query.withFilters(newFilters).locationWithPathname('/mentions');
  };
}

export default withRouter(Demographics);
