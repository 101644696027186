window.reactHelperComponents = [];

import React from 'react';
import { createRoot } from 'react-dom/client';
import { forEach } from 'lodash-es';
import { AppContainer } from 'react-hot-loader';

const setup = () => window.addEventListener('DOMContentLoaded', installEmbeddedComponents);

const installEmbeddedComponents = function () {
  window.reactHelperComponents = [];

  return forEach(document.querySelectorAll('[data-react-component]:not(.installed)'), (e) => {
    let component = new ReactComponent(e);
    component.install();
    window.reactHelperComponents.push(component);

    e.classList.add('installed');
  });
};

const renderReloadableComponent = function (ComponentClass, root, props = {}) {
  root.render(
    <AppContainer>
      <ComponentClass {...props} />
    </AppContainer>
  );
};

class ReactComponent {
  constructor(element) {
    this.element = element;
    this.componentClassName = this.element.getAttribute('data-react-component');
    this.componentClass = require(`../components/${this.componentClassName}`);
    if (this.componentClass.default) {
      this.componentClass = this.componentClass.default;
    }

    this.componentProps = JSON.parse(this.element.getAttribute('data-react-props'));
  }

  install() {
    const root = createRoot(this.element);
    this.component = React.createElement(this.componentClass, this.componentProps);
    root.render(this.component);
  }
}

export { setup, renderReloadableComponent };
